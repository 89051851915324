import { Component } from 'react'
import axios from 'axios'
import { withAuth } from 'react-oidc-context'
import { PortType } from '../enums/portType.enum'
import { SignalInterface } from '../enums/signalInterface.enum'

interface CreateDeviceProps {
  setShow: (value: boolean) => void
}

interface CreateDeviceFormPort {
  type: PortType
  signal_name: string
  signal_short_name: string
  signal_interface: SignalInterface
}

interface CreateDeviceState {
  manufacturer: string
  model: string
  categories: string[]
  inputPorts: CreateDeviceFormPort[]
  outputPorts: CreateDeviceFormPort[]
  hybridPorts: CreateDeviceFormPort[]
}

class CreateDevice extends Component<CreateDeviceProps, CreateDeviceState> {
  private readonly setShow: (value: boolean) => void

  constructor(props: CreateDeviceProps) {
    super(props)
    this.state = {
      manufacturer: '',
      model: '',
      categories: [],
      inputPorts: [],
      outputPorts: [],
      hybridPorts: [],
    }
    this.createDevice = this.createDevice.bind(this)
    this.setShow = props.setShow
    this.addInput = this.addInput.bind(this)
    this.addOutput = this.addOutput.bind(this)
    this.addHybridPort = this.addHybridPort.bind(this)
    this.renderInputPort = this.renderInputPort.bind(this)
    this.renderOutputPort = this.renderOutputPort.bind(this)
    this.renderHybridPorts = this.renderHybridPorts.bind(this)
  }

  private createDevice = async (): Promise<void> => {
    const url = `${process.env.REACT_APP_BACKEND_URI}/v1/device/`
    const { manufacturer, model, categories, inputPorts, outputPorts, hybridPorts } = this.state

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { auth } = this.props
    const token = auth.user?.access_token
    const authorizationHeader = token ? `Bearer ${token}` : ''

    await axios({
      method: 'post',
      url,

      data: {
        manufacturer,
        model,
        categories,
        ports: [...inputPorts, ...outputPorts, ...hybridPorts],
        width: 400,
        height: 200,
      },
      headers: { 'Content-type': 'application/json', Authorization: authorizationHeader },
    })
      .then(() => {
        this.setShow(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  private addInput(): void {
    const { inputPorts } = this.state
    inputPorts.push({
      type: PortType.SDI_INPUT,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.SDI,
    })
    this.setState({ inputPorts })
  }

  private addOutput(): void {
    const { outputPorts } = this.state
    outputPorts.push({
      type: PortType.SDI_OUTPUT,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.SDI,
    })
    this.setState({ outputPorts })
  }

  private addHybridPort(): void {
    console.log('addHybridPort')
    const { hybridPorts } = this.state
    hybridPorts.push({
      type: PortType.ETHERNET,
      signal_name: '',
      signal_short_name: '',
      signal_interface: SignalInterface.ETHERNET,
    })
    this.setState({ hybridPorts })
  }

  private toCategoryString(categories: string[]): string {
    return categories.map((category) => category).join(', ')
  }

  private renderInputPort(i: number): JSX.Element {
    const { inputPorts } = this.state
    const port = inputPorts[i]

    return (
      <div key={`createNewDeviceForm-input${i}`} className="flex flex-col">
        <select
          className="createNewDeviceForm-input-interface border border-grey-dark border-2
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case 'sdi':
                port.type = PortType.SDI_INPUT
                break
              case 'hdmi':
                port.type = PortType.HDMI_INPUT
                break
              case SignalInterface.KLINKE_3_5:
                port.type = PortType.KLINKE_3_5_INPUT
                break
              case SignalInterface.KLINKE_6_3:
                port.type = PortType.KLINKE_6_3_INPUT
                break
              case SignalInterface.XLR:
                port.type = PortType.XLR_INPUT
                break
              default:
                port.type = PortType.SDI_INPUT
                break
            }
            // The components state needs to be updated in the onChange event to trigger re-rendering
            this.setState({ inputPorts })
          }}
        >
          <option value={SignalInterface.SDI}>SDI</option>
          <option value={SignalInterface.HDMI}>HDMI</option>
          <option value={SignalInterface.KLINKE_3_5}>Klinke 3.5mm</option>
          <option value={SignalInterface.KLINKE_6_3}>Klinke 6.3mm</option>
          <option value={SignalInterface.XLR}>XLR</option>
        </select>
        <input
          type="text"
          className="createNewDeviceForm-input-signal-short border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
          name="signal-short"
          placeholder="Set signal short name"
          value={port.signal_short_name}
          onChange={(e) => {
            port.signal_short_name = e.target.value
            this.setState({ inputPorts })
          }}
        />
      </div>
    )
  }

  private renderOutputPort(i: number): JSX.Element {
    const { outputPorts } = this.state
    const port = outputPorts[i]

    return (
      <div key={`createNewDeviceForm-output${i}`} className="flex flex-col">
        <select
          className="createNewDeviceForm-output-interface border border-grey-dark border-2
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case SignalInterface.SDI:
                port.type = PortType.SDI_OUTPUT
                break
              case SignalInterface.HDMI:
                port.type = PortType.HDMI_OUTPUT
                break
              case SignalInterface.KLINKE_3_5:
                port.type = PortType.KLINKE_3_5_OUTPUT
                break
              case SignalInterface.KLINKE_6_3:
                port.type = PortType.KLINKE_6_3_OUTPUT
                break
              case SignalInterface.XLR:
                port.type = PortType.XLR_OUTPUT
                break
              default:
                port.type = PortType.SDI_OUTPUT
                break
            }
            this.setState({ outputPorts })
          }}
        >
          <option value={SignalInterface.SDI}>SDI</option>
          <option value={SignalInterface.HDMI}>HDMI</option>
          <option value={SignalInterface.KLINKE_3_5}>Klinke 3.5mm</option>
          <option value={SignalInterface.KLINKE_6_3}>Klinke 6.3mm</option>
          <option value={SignalInterface.XLR}>XLR</option>
        </select>
        <input
          type="text"
          className="createNewDeviceForm-output-signal-short border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
          name="signal-short"
          placeholder="Set signal short name"
          onChange={(e) => {
            port.signal_short_name = e.target.value
            this.setState({ outputPorts })
          }}
          value={port.signal_short_name}
        />
      </div>
    )
  }

  private renderHybridPorts(i: number): JSX.Element {
    const { hybridPorts } = this.state
    const port = hybridPorts[i]

    return (
      <div key={`createNewDeviceForm-hybridPort${i}`} className="flex flex-col">
        <select
          className="createNewDeviceForm-hybridPort-interface border border-grey-dark border-2
                            rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light focus:ring-1
                            focus:ring-blue-third"
          onChange={(e) => {
            port.signal_interface = e.target.value.toLowerCase() as SignalInterface
            switch (e.target.value.toLowerCase()) {
              case SignalInterface.ETHERNET:
                port.type = PortType.ETHERNET
                break
              default:
                port.type = PortType.ETHERNET
                break
            }
            this.setState({ hybridPorts })
          }}
        >
          <option value={SignalInterface.ETHERNET}>Ethernet</option>
        </select>
        <input
          type="text"
          className="createNewDeviceForm-hybridPort-signal-short border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
          name="signal-short"
          placeholder="Set signal short name"
          onChange={(e) => {
            port.signal_short_name = e.target.value
            this.setState({ hybridPorts })
          }}
          value={port.signal_short_name}
        />
      </div>
    )
  }

  // <!-- COPIED CODE FROM https://tailwindui.com/components/application-ui/overlays/modals -->
  public render(): JSX.Element {
    const { manufacturer, model, categories, inputPorts, outputPorts, hybridPorts } = this.state

    return (
      <div
        className="inline-block align-bottom bg-white rounded-lg text-left text-base xl:text-lg overflow-hidden
          shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-screen-xl sm:w-full"
      >
        <form action="#" id="createNewDeviceForm">
          <div className="bg-blue-main px-6 pt-7 sm:px-8 sm:pt-8">
            <div className="flex items-start flex-col">
              <div className="text-white">Add a device</div>
              <div
                className="bg-white text-black rounded-md my-8 align-middle max-w-screen-xl
                  w-full px-6 p-8 h-full"
              >
                <div className="flex flex-col">
                  <div className="flex flex-row justify-between">
                    <div>Manufacturer</div>
                    <div className="w-2/4">
                      <input
                        type="text"
                        id="createNewDeviceForm-manufacturer"
                        value={manufacturer}
                        onChange={(e) => {
                          this.setState({ manufacturer: e.target.value })
                        }}
                        name="manufacturer"
                        placeholder="Set manufacturer"
                        className="w-full border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div>Model</div>
                    <div className="w-2/4">
                      <input
                        type="text"
                        id="createNewDeviceForm-model"
                        name="model"
                        value={model}
                        onChange={(e) => {
                          this.setState({ model: e.target.value })
                        }}
                        placeholder="Set model"
                        className="w-full border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between">
                    <div>Categories (comma separated)</div>
                    <div className="w-2/4">
                      <input
                        type="text"
                        id="createNewDeviceForm-category"
                        name="category"
                        value={this.toCategoryString(categories)}
                        onChange={(e) => {
                          this.setState({ categories: e.target.value.split(', ') })
                        }}
                        placeholder="Set category"
                        className="w-full border border-grey-dark border-2
                              rounded-md bg-grey-light p-1 mb-5 text-base xl:text-lg font-light placeholder-grey-dark
                              focus:ring-1 focus:ring-blue-third"
                      />
                    </div>
                  </div>
                  <div className="flex flex-row justify-between pb-6 pt-6">
                    <div>Inputs</div>
                    <div id="createNewDeviceForm-inputs" className="w-2/4">
                      {inputPorts.map((port, i) => this.renderInputPort(i))}
                    </div>
                  </div>
                  <input
                    type="button"
                    value="Add input"
                    onClick={this.addInput}
                    className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark border-2 rounded-md px-2"
                  />
                  <div className="flex flex-row justify-between pb-6 pt-6">
                    <div>Outputs</div>
                    <div id="createNewDeviceForm-outputs" className="w-2/4">
                      {outputPorts.map((port, i) => this.renderOutputPort(i))}
                    </div>
                  </div>
                  <input
                    type="button"
                    value="Add output"
                    className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark border-2 rounded-md px-2"
                    onClick={this.addOutput}
                  />

                  <div className="flex flex-row justify-between pb-6 pt-6">
                    <div>Bidirectional Ports</div>
                    <div id="createNewDeviceForm-bidirectional" className="w-2/4">
                      {hybridPorts.map((port, i) => this.renderHybridPorts(i))}
                    </div>
                  </div>
                  <input
                    type="button"
                    value="Add bidirectional port"
                    className="self-end bg-transparent cursor-pointer font-light hover:text-grey-dark border
                        border-grey-dark border-2 rounded-md px-2"
                    onClick={this.addHybridPort}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="bg-blue-main px-6 pb-7 sm:px-8 sm:flex sm:flex-row sm:justify-between">
            <button
              onClick={() => this.setShow(false)}
              type="button"
              className="w-full inline-flex justify-center bg-transparent border border-transparent text-white
                  font-light cursor-pointer hover:text-grey-dark sm:w-auto"
            >
              Cancel
            </button>
            <button
              onClick={this.createDevice}
              type="button"
              className="w-full inline-flex justify-center bg-transparent border border-transparent text-white
                  font-light cursor-pointer hover:text-grey-dark sm:w-auto"
            >
              Create the device
            </button>
          </div>
        </form>
      </div>
    )
  }
}

export default withAuth(CreateDevice)
